import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './scss/activity.scss';
import ProfileNavbar from './ProfileNavbar';
import { find, toSlug } from '../../utils/helpers';
import { config } from '../../config';
import Comment from '../Workspaces/Comment';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import Spinner from '../Global/Spinner';

const Activity = () => {
    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // New isLoading state

    useEffect(() => {
        const fetchUserActivities = async () => {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                setIsLoading(false);
                return false;
            }

            try {
                const response = await fetch(config.API_URI + '/api/users/me/activities', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch activities');
                }

                const activities = await response.json();
                setActivities(activities);
            } catch (error) {
                console.error('Error fetching user activities:', error);
            } finally {
                setIsLoading(false); // Stop loading once data is fetched or error occurs
            }
        };

        fetchUserActivities();
    }, [user]);

    if (!user) {
        return <div>User not found.</div>;
    }

    return (
        <div className={`profile-page${isLoading ? ' card-loading' : ''}`}>
            <ProfileNavbar />
            <div className="activity-container">
                <div className="table-body">
                    {
                        (isLoading || userLoading) && (
                            <Spinner size={30} color="#3498db" speed={1.5} />
                        )
                    }
                    {
                        !(isLoading || userLoading) && activities && (
                            <div className="activities">
                                {activities?.map((activity) => (
                                    activity.actionType === 'ADDED_COMMENT' ? (
                                        <Comment key={activity._id} activity={activity} cardId={activity.card} />
                                    ) : (
                                        <div key={activity._id} className="activity">
                                            {activity.initiator?.profilePicture ? (
                                                <img
                                                    src={activity.initiator.profilePicture}
                                                    alt={activity.initiator.name}
                                                    className="activity__profile-img"
                                                />
                                            ) : (
                                                <ImagePlaceholder size={35} text={activity.initiator.name} />
                                            )}

                                            <div className="activity__content">
                                                <div className="activity__header">
                                                    <strong>{activity.initiator.name}</strong>
                                                    <div className="activity__details" dangerouslySetInnerHTML={{ __html: activity.details }} />
                                                </div>

                                                <span>
                                                    {new Date(activity.createdAt).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric', // Added seconds here
                                                        hour12: true
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        )
                    }


                </div>
            </div>

            {
                (!isLoading && !userLoading) && activities?.length === 0 && (

                    <div className="empty-activity">
                        <h2>No activities yet</h2>
                        <p>You haven't performed any activities yet.</p>
                    </div>
                )
            }
        </div>
    );
};

export default Activity;
