import React from 'react';
import PropTypes from 'prop-types';
import './ImagePlaceholder.scss';

const ImagePlaceholder = ({
    text,
    size = 30,
    textColor = "var(--single-card-text-color)",
    radius = "50%",
    fontSize = "15px",
    className = ""
}) => {
    // Generate a consistent color based on the input text
    const generateColorFromText = (text) => {
        if (!text) return '#454f5999'; // Default color
        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = `#${((hash >> 24) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 16) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 8) & 0xFF).toString(16).padStart(2, '0')}`.slice(0, 7);
        return color;
    };

    const bgColor = generateColorFromText(text);
    const computedTextColor = getContrastYIQ(bgColor);

    const style = {
        backgroundColor: bgColor,
        width: size,
        height: size,
        color: computedTextColor,
        borderRadius: radius,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: fontSize,
        textTransform: 'uppercase',
    };

    return (
        <div className={`image-placeholder ${className}`} style={style}>
            {text ? text.charAt(0) : ''}
        </div>
    );
};

// Function to determine text color contrast for readability
const getContrastYIQ = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
};

ImagePlaceholder.propTypes = {
    text: PropTypes.string,
    size: PropTypes.number,
    textColor: PropTypes.string,
    radius: PropTypes.string,
    fontSize: PropTypes.string,
    className: PropTypes.string,
};

export default ImagePlaceholder;
