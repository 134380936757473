import React from 'react';
import './scss/Home.scss';
import { Link } from 'react-router-dom';
import Logo from '../components/Header/Logo';

const Home = () => {
    return (
        <section className="hero">
            <div className="hero__container">
                <div className="hero_logo">
                    <p>
                        <Logo />
                        <sup className="logo-beta">Beta </sup>
                    </p>
                </div>
                <h1 className="hero__title">
                    Streamline Your Tasks, Collaborate with Teammates, and Maximize Your Tools
                </h1>
                <p className="hero__subtitle">
                    Stay Organized, No Matter Where Your Team Works.
                </p>
                <span className='deploy-version'>v0.00</span>
                <div className="hero__actions">
                    <Link to="/login" className="hero__login" >Log In</Link>
                    <Link to="/signup" className="hero__cta">Get Zoobbe for Free</Link>
                </div>

            </div>
        </section>
    );
};

export default Home;
