import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk to fetch the board by ID
export const fetchBoardById = createAsyncThunk(
    'board/fetchBoardById',
    async (boardId, { getState, rejectWithValue }) => {
        const state = getState();
        const _boardId = state.board.boardId || null;
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/boards/${boardId || _boardId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the board');
            }

            const fetchedBoard = await response.json();
            return fetchedBoard || { actionLists: [] };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk to upload the background image and set it for the board
export const uploadBackgroundImage = createAsyncThunk(
    'board/uploadBackgroundImage',
    async ({ boardId, imageUrl }, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/boards/boards-background`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ boardId, url: imageUrl }),
            });

            if (!response.ok) {
                throw new Error('Failed to upload background image');
            }

            return imageUrl; // Return the uploaded image URL
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const boardSlice = createSlice({
    name: 'board',
    initialState: {
        boardId: null,
        board: null,
        selectedBackgroundUrl: '',  // Temporary selected background image URL
        boardBackgroundUrl: '',  // Actual background image URL after upload
        isLoading: false,
        error: null,
    },
    reducers: {
        setBoardId: (state, action) => {
            state.boardId = action.payload;
        },
        setSelectedBackground: (state, action) => {
            state.selectedBackgroundUrl = action.payload;  // Optimistically set the selected background
        },
        setBoardBackground: (state, action) => {
            state.boardBackgroundUrl = action.payload;  // Set the actual background after upload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoardById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchBoardById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.board = action.payload;
            })
            .addCase(fetchBoardById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadBackgroundImage.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(uploadBackgroundImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.boardBackgroundUrl = action.payload;  // Save the uploaded background URL
                state.selectedBackgroundUrl = '';  // Clear the optimistically selected URL
            })
            .addCase(uploadBackgroundImage.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { setBoardId, setSelectedBackground, setBoardBackground } = boardSlice.actions;
export default boardSlice.reducer;
