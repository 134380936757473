import React, { useState, useEffect, useRef } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Atlaskit Drag-and-Drop Imports
import {
    draggable,
    dropTargetForElements,
    monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
    attachClosestEdge,
    Edge,
    extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

// Local Components
import Card from './Card';
import AddCardButton from './AddCardButton';

// Hooks
import useOutsideClick from '../../hooks/useOutsideClick';
import useIsScrolling from '../../hooks/useIsScrolling';

// Redux Actions
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

// Config
import { config } from '../../config';

// Virtualization
import { useVirtualizer } from '@tanstack/react-virtual';
import { fetchBoardById } from '../../redux/Slices/boardSlice';
import { fetchCardsByListId } from '../../redux/Slices/actionlistSlice';


const ActionListPND = ({ actionList, isOpen, onToggleOptions, onDelete, board, setBoard, boardId, boardScrollRef }) => {

    const { _id, title, cards } = actionList;


    const dispatch = useDispatch();
    // const actionListState = useSelector((state) => state.actionList.actionLists[_id] || { details: null, cards: [], status: 'idle', error: null });

    // const { cards, status } = actionListState;
    // console.log({ cards });


    // useEffect(() => {
    //     // Fetch cards only if the status is 'idle' and no cards are loaded
    //     if (status === 'idle' && cards.length === 0) {
    //         dispatch(fetchCardsByListId(_id));
    //     }
    // }, [dispatch, _id, status, cards.length]);

    const [showForm, setShowForm] = useState(false);
    const [showTopForm, setShowTopForm] = useState(false);
    const [cardTitle, setCardTitle] = useState('');
    const [cardDescription, setCardDescription] = useState('');
    const [currentCards, setCurrentCards] = useState(cards);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [listTitle, setListTitle] = useState(title);
    const [isListChanged, setListChanged] = useState(false);

    const cardTitleRef = useRef(null);
    const cardTopTitleRef = useRef(null);
    const actionListContainerRef = useRef(null);
    const actionListTitleRef = useRef(null);

    const cardListRef = useRef(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [closestEdge, setClosestEdge] = useState(null);

    const isScrolling = useIsScrolling(actionListContainerRef);


    const actionListRef = useRef(null);
    const listHeaderRef = useRef(null);
    const addNewCardFormRef = useRef(null);
    const hiddenListRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(720);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const calculateHeight = () => {
            const totalHeight = 770; // Base height
            const headerHeight = listHeaderRef.current?.offsetHeight + 10 || 0;
            const footerHeight = addNewCardFormRef.current?.offsetHeight + 10 || 0;

            setContainerHeight(totalHeight - headerHeight - footerHeight);
        };

        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, [showForm]);

    const moveActionList = (sourceId, targetId) => {
        setBoard((prevBoard) => {
            const updatedActionLists = [...prevBoard.actionLists];

            const sourceIndex = updatedActionLists.findIndex(list => list._id === sourceId);
            const targetIndex = updatedActionLists.findIndex(list => list._id === targetId);

            if (sourceIndex === -1 || targetIndex === -1) return prevBoard;

            // Move the source action list to the target position
            const [movedList] = updatedActionLists.splice(sourceIndex, 1);
            updatedActionLists.splice(targetIndex, 0, movedList);

            // Reassign the order based on the new array position
            const reorderedActionLists = updatedActionLists.map((list, index) => ({
                ...list, // Clone to avoid mutation
                order: index + 1, // Assign new order value
            }));

            return {
                ...prevBoard,
                actionLists: reorderedActionLists,
            };
        });
    };


    const moveCard = (cardId, targetListId, targetPosition) => {
        setBoard((prevBoard) => {
            let cardToMove = null;
            let sourceListId = null;

            // Find the source list and card to move
            const updatedActionLists = prevBoard.actionLists.map((list) => {
                const newList = { ...list }; // Clone the list to avoid mutation
                if (newList.cards?.some((card) => card._id === cardId)) {
                    sourceListId = newList._id;
                    newList.cards = newList.cards.reduce((acc, card) => {
                        if (card._id === cardId) {
                            cardToMove = { ...card, actionList: { _id: targetListId } }; // Update actionList._id to targetListId
                            return acc; // Exclude this card from the new array
                        }
                        return [...acc, card]; // Include other cards
                    }, []);
                }
                return newList;
            });

            // Ensure card is found
            if (!cardToMove) {
                console.error("Card not found");
                return prevBoard;
            }

            // Update the target list with the card at the target position
            const finalActionLists = updatedActionLists.map((list) => {
                const newList = { ...list }; // Clone the list
                if (newList._id === targetListId) {
                    const newTargetCards = [...newList.cards];
                    newTargetCards.splice(targetPosition, 0, cardToMove); // Insert card at target position

                    // Reorder all cards in the target list and update their order and actionList._id
                    newList.cards = newTargetCards.map((card, index) => ({
                        ...card,
                        order: index, // Update order
                        actionList: { _id: targetListId }, // Ensure all cards in target list have the correct list ID
                    }));
                }
                return newList; // Return the modified or unmodified list
            });

            // Reorder cards in the source list if it has been modified
            if (sourceListId) {
                finalActionLists.forEach((list) => {
                    if (list._id === sourceListId) {
                        list.cards = list.cards.map((card, index) => ({
                            ...card,
                            order: index, // Update order for the source list
                        }));
                    }
                });
            }

            return { ...prevBoard, actionLists: finalActionLists };
        });
    };


    const updateBoardOrder = async () => {
        const actionListOrders = board.actionLists.map(list => ({ _id: list._id, order: list?.order }));

        try {
            const token = localStorage.getItem('accessToken');
            await fetch(`${config.API_URI}/api/boards/${boardId}/order`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ actionListOrders }), // Assuming card orders are not being updated
            });
        } catch (error) {
            console.error('Error updating action list order:', error);
        }
    };

    const updateCardsOrder = async (sourceListId, cardId, targetListId, targetPosition) => {
        const token = localStorage.getItem('accessToken');

        try {
            await fetch(`${config.API_URI}/api/boards/${boardId}/cards/order`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ sourceListId, cardId, targetListId, targetPosition }),
            });
        } catch (error) {
            console.error('Error moving card:', error);
        }
    };


    const [isDragging, setDragging] = useState(false);

    useEffect(() => {
        const element = actionListRef.current;
        const containerElement = actionListContainerRef.current;

        if (!element) return; // Early exit if ref.current is null or undefined

        const autoScroll = autoScrollForElements({
            element: containerElement,
            getConfiguration: () => ({
                maxScrollSpeed: 'fast',
                allowedAxis: 'all',
            }),
        });

        const combined = combine(
            // Draggable configuration for the action list
            draggable({
                element,
                dragHandle: element,
                getInitialData: () => ({ ...actionList, type: 'ACTIONLIST' }),

                onDrag() {
                    setDragging(true);
                    // Add class to all other items
                    document.querySelectorAll('.action-list-inner').forEach(item => {
                        if (!item.classList.contains('dragging-start') || !item.classList.contains('other-dragging')) {
                            item.classList.add('other-dragging');
                        }
                    });
                },
                onDrop() {
                    setDragging(false);
                    // Remove class from all other items
                    document.querySelectorAll('.action-list-inner').forEach(item => {
                        item.classList.remove('other-dragging');
                    });
                }
            }),

            // Drop target configuration for the card list
            dropTargetForElements({
                element: cardListRef.current,
                getData: () => ({ ...actionList, type: 'ACTIONLIST' }),
                canDrop: (args) => args.source.data.type === "CARD",
                getIsSticky: () => true,

                // Events for card drag and drop interactions
                onDragEnter: () => setIsDraggingOver(true),
                onDragLeave: () => setIsDraggingOver(false),
                onDragStart: () => setIsDraggingOver(true),
                onDrop: () => setIsDraggingOver(false),
            }),

            // Drop target configuration for the action list
            dropTargetForElements({
                element,
                getIsSticky: () => true,
                getData: ({ input, element }) => {
                    const data = { ...actionList, type: 'ACTIONLIST' };
                    return attachClosestEdge(data, {
                        input,
                        element,
                        allowedEdges: ["left", "right"],
                    });
                },

                // Events when dragging enters the action list
                onDragEnter: (args) => {
                    setClosestEdge(extractClosestEdge(args.self.data));
                    const { source, self } = args;
                    const target = self;

                    // If dragging a card, set dragging state and move the card within the list
                    if (source.data.type === 'CARD') {
                        setIsDraggingOver(true);
                        moveCard(source.data._id, target.data._id, 0); // Placeholder, adjust as needed for actual card movement logic
                    }
                    // If dragging a list, set appropriate dragging state
                    else if (source.data.type === 'ACTIONLIST') {
                        // setIsDraggingOver(true);
                        moveActionList(source.data._id, target.data._id); // Assuming you have a moveActionList function
                    }
                },

                // Continuously update closest edge while dragging
                onDrag: (args) => {
                    const closestEdge = extractClosestEdge(args.self.data);
                    setClosestEdge(closestEdge);
                },

                // Events when dragging leaves the action list
                onDragLeave: () => {
                    setClosestEdge(null);
                    setIsDraggingOver(false);
                },

                // Events when an item is dropped onto the action list
                onDrop: ({ source, self }) => {
                    setClosestEdge(null);
                    setIsDraggingOver(false);
                    setDragging(false);

                    if (source.data.type === 'CARD') {
                        // Handle card movement logic
                        const card = self.data.cards.find(c => c._id === source.data._id);
                        updateCardsOrder(source.data.actionList._id, source.data._id, self.data._id, card?.order);
                    }
                    else if (source.data.type === 'ACTIONLIST') {
                        // Handle action list movement logic
                        moveActionList(source.data._id, self.data._id);
                        updateBoardOrder();

                    }
                },
            })

        );


        return () => {

            combined();
            autoScroll();

        };

    }, [actionList, actionListRef.current]); // Re-run effect only if actionList or ref.current changes


    const [listHighlight, setListHighlight] = useState(false);

    useEffect(() => {
        const element = actionListRef.current;

        if (!element) return;

        const monitorConfig = {
            element,
            onDrag(args) {

                const { source, location } = args;
                const target = location.current.dropTargets[0];

                if (!target || target.data.type !== 'ACTIONLIST') { // Check if target is a card
                    return;
                }

                if (target.data._id === actionList._id && source.data.type === 'ACTIONLIST' && target.data.type === 'ACTIONLIST') {
                    setListHighlight(true);
                } else {
                    setListHighlight(false);
                }
            },
            onDrop() {
                setListHighlight(false);
            },
        };

        return monitorForElements(monitorConfig);
    }, [actionList]);




    // Update currentCards state when cards prop changes
    useEffect(() => {
        setCurrentCards(cards);

    }, [cards]);

    useEffect(() => {
        const updatedList = board.actionLists.find((list) => list._id === _id);
        if (updatedList) {
            setCurrentCards(updatedList.cards);
        }
    }, [board, _id]);

    // Add new card to the list
    const handleAddCard = async (e, position) => {
        e.preventDefault();

        const optimisticCard = {
            id: Date.now(), // Temporary ID
            actionListId: _id,
            title: cardTitle,
            description: cardDescription,
            users: [],
            isOptimistic: true, // Identifies the optimistic card
        };

        // Add the optimistic card to the list of current cards at the correct position
        setCurrentCards((prevCards) =>
            position === 'top' ? [optimisticCard, ...prevCards] : [...prevCards, optimisticCard]
        );

        // Clear the form inputs and hide the form
        setCardTitle('');
        setCardDescription('');
        handleShowForm(position);


        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + '/api/cards', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    actionListId: _id,
                    title: cardTitle,
                    description: cardDescription,
                    position,
                    users: [],
                }),
            });

            if (!response.ok) throw new Error('Failed to add card');

            const { card: newCard } = await response.json();

            // Replace the optimistic card with the real card
            setCurrentCards((prevCards) =>
                position === 'top'
                    ? [newCard, ...prevCards.filter((card) => !card.isOptimistic)]
                    : [...prevCards.filter((card) => !card.isOptimistic), newCard]
            );

            // Update the board state to reflect the new card in the correct list
            setBoard((prevBoard) => ({
                ...prevBoard,
                actionLists: prevBoard.actionLists.map((list) =>
                    list._id === _id
                        ? {
                            ...list,
                            cards: position === 'top'
                                ? [newCard, ...list.cards]
                                : [...list.cards, newCard],
                        }
                        : list
                ),
            }));

            // Scroll to the top or bottom depending on position
            position === 'top' ? scrollToTop() : scrollToBottom();

        } catch (error) {
            // Remove the optimistic card if the request fails
            setCurrentCards((prevCards) => prevCards.filter((card) => !card.isOptimistic));

            // Show an error toast
            dispatch(showToast({ message: `Error adding card: ${error.message}`, type: 'error' }));
        }
    };




    // Scroll to bottom after adding a new card
    const scrollToBottom = () => {
        setTimeout(() => {
            if (actionListContainerRef.current) {
                actionListContainerRef.current.scrollTop = actionListContainerRef.current.scrollHeight;
            }
        }, 0);
    };
    const scrollToTop = () => {
        setTimeout(() => {
            if (actionListContainerRef.current) {
                actionListContainerRef.current.scrollToBottom = actionListContainerRef.current.scrollHeight;
            }
        }, 0);
    };

    // Show the add card form
    const handleShowForm = (position) => {

        console.log({ position });

        if (position == 'top') {
            setShowTopForm(true)
            setShowForm(false);

        } else {
            setShowForm(true);
            setShowTopForm(false)

        }
        setTimeout(() => {
            if (cardTitleRef.current) {
                cardTitleRef.current.focus();
                scrollToBottom();

            }
            if (cardTopTitleRef.current) {
                cardTopTitleRef.current.focus();
                scrollToTop();
            }
        }, 0);
    };

    // Hide the add card form
    const handleHideForm = () => {
        setShowForm(false);
        setShowTopForm(false);
        setCardTitle('');
    };

    // Delete the action list
    const handleDeleteList = async () => {
        setIsDeleting(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/actionLists/delete/${_id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to delete the list');

            onDelete(_id);
            dispatch(showToast({ message: 'List deleted successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error deleting the list: ' + error.message, type: 'error' }));
        } finally {
            setIsDeleting(false);
        }
    };

    // Archive the action list
    const handleArchivedList = async () => {
        setIsArchiving(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/actionLists/archive/${_id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error('Failed to archive the list');

            dispatch(showToast({ message: 'List archived successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error archiving the list: ' + error.message, type: 'error' }));
        } finally {
            setIsArchiving(false);
        }
    };

    // Update the list title
    const handleUpdateListTitle = async (newTitle) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/actionLists/${_id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ title: newTitle }),
            });

            if (!response.ok) throw new Error('Failed to update the list title');

            // dispatch(fetchWorkspaces());
            // dispatch(showToast({ message: 'List title updated successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error updating the list title: ' + error.message, type: 'error' }));
        }
    };

    // Handle clicking outside the list title input to save changes
    useOutsideClick(actionListTitleRef, () => {
        if (isListChanged) {
            handleUpdateListTitle(listTitle);
            setListChanged(false);
        }
    });

    // Adjust textarea height based on content for card title
    useEffect(() => {
        if (cardTitleRef.current) {
            adjustTextareaHeight(cardTitleRef.current, cardTitle, 16);
        }
        if (cardTopTitleRef.current) {
            adjustTextareaHeight(cardTopTitleRef.current, cardTitle, 16);
        }
    }, [cardTitle]);

    // Adjust textarea height based on content for list title
    useEffect(() => {
        if (actionListTitleRef.current) {
            adjustTextareaHeight(actionListTitleRef.current, listTitle, 6);
        }
    }, [listTitle, isEditing]);

    // Adjust the height of the textarea based on its content
    const adjustTextareaHeight = (textarea, content, minus) => {
        textarea.style.height = '24px';
        const scrollHeight = textarea.scrollHeight - minus;
        textarea.style.height = `${scrollHeight}px`;
    };


    const draggingStyle = {
        opacity: 0,
        transform: 'scale(1.02)',
        boxShadow: ' var(--popover-box-shadow)',
        transition: 'all 0.2s ease',
    };

    const placeholderStyle = {
        backgroundColor: '#f0f0f0',
        border: '2px dashed #bbb',
        height: '100px',
        margin: '10px 0',
        display: 'block',
        transition: 'all 0.2s ease',
    };

    // Define styles based on dragging state
    const style = {
        ...(listHighlight ? { opacity: '0', backgroundColor: 'black', border: 'none', userSelect: 'none' } : {}),
        ...(isDraggingOver ? { outline: '2px solid #0a66ff' } : {}),
        marginTop: '2px',
        // ...(isDragging ? draggingStyle : {})
    };

    const virtualizer = useVirtualizer({
        horizontal: false,
        count: currentCards?.length,
        getScrollElement: () => actionListContainerRef.current,
        estimateSize: (index) => {
            const card = currentCards[index];
            return card.sizeHint || 120;
        },
        overscan: 5
    });

    const measureCardSize = (el, index) => {
        if (el) {
            const height = el.getBoundingClientRect().height;
            virtualizer.measureElement(el);

            if (Object.isExtensible(currentCards[index])) {
                currentCards[index].sizeHint = height;
            }
        }
    };

    const fetchCardsByActionListId = async (actionListId, skip = 0, limit = 20) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(
                `${config.API_URI}/api/actionLists/${actionListId}/cards?skip=${skip}&limit=${limit}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const { cards } = await response.json();

            setBoard((prevBoard) => {
                const updatedActionLists = prevBoard.actionLists.map((list) => {
                    if (list._id === actionListId) {
                        // Append new cards to the existing cards array
                        return {
                            ...list,
                            cards: [...list.cards, ...cards],
                        };
                    }
                    return list;
                });

                return {
                    ...prevBoard,
                    actionLists: updatedActionLists,
                };
            });
        } catch (error) {
            console.error('Error fetching cards:', error);
        }
    };


    useEffect(() => {
        if (_id) {
            // fetchCardsByActionListId(_id, cards.length, 20); // Fetch new cards if available
        }
    }, [_id]);

    const handleBlur = () => {
        if (isListChanged) {
            handleUpdateListTitle(listTitle);
            setListChanged(false);
        }
        setIsEditing(false);
    };

    const virtualCards = virtualizer.getVirtualItems();

    return (
        <div
            style={{
                ...(listHighlight ? { backgroundColor: 'var(--single-card-background-color)', borderRadius: '15px', } : {}),
            }}
            className="action-list-content"
            ref={actionListRef}

        >
            <div
                className={`action-list-inner${isDragging ? ' dragging-start' : ''}`}
                style={style}
            >
                <div className="list-header" ref={listHeaderRef}>

                    <div className="list-title">

                        {!isEditing ? (
                            <span
                                ref={hiddenListRef}
                                className="update-list-title"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setIsEditing(true);
                                    setTimeout(() => actionListTitleRef.current.focus(), 0);
                                }}
                            >
                                {listTitle || ''}
                            </span>
                        ) : (
                            <textarea
                                ref={actionListTitleRef}
                                className="update-list-title"
                                placeholder="Enter list title"
                                rows="1"
                                value={listTitle}
                                required
                                onChange={(e) => {
                                    setListTitle(e.target.value);
                                    setListChanged(true);
                                }}
                                onFocus={(e) => e.target.select()}
                                spellCheck="false"
                                autoCorrect="off"
                                autoCapitalize="off"
                                onBlur={handleBlur}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault(); // Prevents the default behavior of creating a new line
                                        handleBlur();
                                    }
                                }}
                            />
                        )}
                        <div className="action-list-option">

                            <span
                                className="action-list-option-icon"
                                data-tooltip-content="Cards"
                                data-tooltip-position="top"
                            >
                                <span>{currentCards?.length}</span>
                            </span>
                            <span
                                className="action-list-option-icon"
                                onClick={() => handleShowForm('top')}
                                data-tooltip-content="Add a card"
                                data-tooltip-position="top"
                            >
                                <span className="material-symbols-outlined">add</span>
                            </span>

                            <span
                                className="action-list-option-icon"
                                onClick={onToggleOptions}
                                data-tooltip-content="More"
                                data-tooltip-position="top"
                            >
                                <span className="material-symbols-outlined">more_horiz</span>
                            </span>
                            {isOpen && (
                                <div className="list-options">
                                    <Link to="#" onClick={handleDeleteList} disabled={isDeleting}>
                                        {isDeleting ? 'Deleting...' : 'Delete'}
                                    </Link>
                                    <Link to="#" onClick={handleArchivedList} disabled={isArchiving}>
                                        {isArchiving ? 'Archiving...' : 'Archive'}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <span className="card-filters-count">
                        {currentCards?.length} {currentCards?.length === 1 ? 'card matches' : 'cards match'} filters
                    </span> */}

                </div>

                <div
                    className={`action-list-container${isScrolling ? ' scrolling' : ''}`}
                    ref={actionListContainerRef}
                    style={{
                        height: virtualizer.getTotalSize() > containerHeight ? containerHeight - 20 : virtualizer.getTotalSize() + (virtualCards.length + 1) * 5,
                        overflowX: 'auto',
                        contain: 'strict',
                        maxHeight: '100%'
                    }}
                >
                    <div
                        className="list-group"
                        style={{
                            height: virtualizer.getTotalSize(),
                            position: 'relative',
                        }}
                    >
                        <ol
                            className="action-card-list"
                            ref={cardListRef}

                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                transform: `translateY(${virtualCards[0]?.start ?? 0}px)`,
                                width: 'calc(100% - 12px)',
                                overflow: 'hidden',
                                padding: '0 6px',
                            }}
                        >
                            {showTopForm && (
                                <li ref={addNewCardFormRef}>

                                    <div className="add-new-card-form top-card-form">
                                        <form onSubmit={(e) => handleAddCard(e, 'top')}>
                                            <textarea
                                                ref={cardTopTitleRef}
                                                name="add-new-card"
                                                placeholder="Enter card title"
                                                rows="1"
                                                value={cardTitle}
                                                onChange={(e) => setCardTitle(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        if (cardTitle.trim() === '') {
                                                            return;
                                                        }
                                                        handleAddCard(e, 'top');
                                                    }
                                                }}
                                                required
                                            />

                                            <div className="footer-buttons">
                                                <button type="submit">Add Card</button>
                                                <button type="button" onClick={handleHideForm}>
                                                    <span className="material-symbols-outlined">close</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </li>

                            )}

                            {/* {currentCards?.map((card) => ( */}
                            {virtualCards.map((virtualCard, index) => (
                                <li
                                    key={currentCards[virtualCard.index]._id || 'card-' + Math.random()}
                                    data-index={virtualCard.index}
                                    // ref={virtualizer.measureElement}
                                    ref={el => measureCardSize(el, virtualCard.index)}
                                    data-cardid={currentCards[virtualCard.index]._id || 'card-' + Math.random()}

                                >

                                    <Card
                                        card={currentCards[virtualCard.index]}
                                        actionListId={_id}
                                        moveCard={moveCard}
                                        moveActionList={moveActionList}
                                        setBoard={setBoard}
                                    />
                                </li>
                            ))}



                        </ol>

                    </div>

                </div>

                {
                    !showForm && (
                        <div className="add-new-card-form-button" ref={addNewCardFormRef}>
                            <AddCardButton onClick={handleShowForm} />
                        </div>
                    )
                }

                {showForm && (

                    <div className="add-new-card-form" ref={addNewCardFormRef}>

                        <form onSubmit={(e) => handleAddCard(e, 'bottom')}>
                            <textarea
                                ref={cardTitleRef}
                                name="add-new-card"
                                placeholder="Enter card title"
                                rows="1"
                                value={cardTitle}
                                onChange={(e) => setCardTitle(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        if (cardTitle.trim() === '') {
                                            return;
                                        }
                                        handleAddCard(e, 'bottom');
                                    }
                                }}
                                required
                            />

                            <div className="footer-buttons">
                                <button type="submit">Add Card</button>
                                <button type="button" onClick={handleHideForm}>
                                    <span className="material-symbols-outlined">close</span>
                                </button>
                            </div>
                        </form>
                    </div>

                )}

            </div>


        </div>
    );
};

export default ActionListPND;
