import React, { createContext, useState, useEffect, useContext } from 'react';
import { config } from '../config';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    // Get the saved theme from localStorage or default to "system"
    const getInitialTheme = () => localStorage.getItem('theme') || 'system';

    const [theme, setTheme] = useState(getInitialTheme);

    useEffect(() => {
        const applyTheme = (currentTheme) => {
            if (currentTheme === 'system') {
                // Detect system theme
                const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
                document.documentElement.setAttribute('data-theme', prefersDark ? 'dark' : 'light');
            } else {
                document.documentElement.setAttribute('data-theme', currentTheme);
            }
        };

        // Apply the selected theme
        applyTheme(theme);

        // Save the theme to localStorage whenever it changes
        localStorage.setItem('theme', theme);

        // Handle system theme changes if "system" is selected
        if (theme === 'system') {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const handleChange = () => applyTheme('system');
            mediaQuery.addEventListener('change', handleChange);
            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
