import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/Slices/thunks';
import ProfileDropdown from '../Profile/ProfileDropdown';
import Notifications from '../Global/Notifications';
import useOutsideClick from '../../hooks/useOutsideClick';
import './index.scss'; // Assuming you have a CSS file for styles
import { config } from '../../config';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { openModal } from '../../redux/Slices/modalSlice';
import SearchResults from './SearchResults';
import Logo, { LogoLight } from './Logo';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import Tooltip from '../Global/Tooltip';
import { setSelectedBackground } from '../../redux/Slices/boardSlice';
import { useTheme } from '../../context/ThemeContext';

// Initialize socket outside of the component to prevent re-initialization on each render
const socket = io(config.API_URI);

const Header = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const [newNotification, setNewNotification] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isShowSearchResult, setSearchResult] = useState(false);
  const [query, setQuery] = useState(""); // Search query
  const [activeNav, setActiveNav] = useState(null);

  const location = useLocation();
  const profileRef = useRef(null);
  const notificationsRef = useRef(null);
  const searchRef = useRef(null);
  const workspaceRef = useRef(null);
  const recentRef = useRef(null);
  const starredRef = useRef(null);

  const { board, isLoading } = useSelector((state) => state.board);
  const { theme } = useTheme();

  const { handlePopoverClick } = useHandlePopoverClick();

  const dispatch = useDispatch();
  const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);

  const [systemTheme, setSystemTheme] = useState('light'); // Default to light

  useEffect(() => {
    // Check the system theme and set it
    const updateSystemTheme = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setSystemTheme(isDarkMode ? 'dark' : 'light');
    };

    // Initial check
    updateSystemTheme();

    // Add a listener for changes in the system theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateSystemTheme);

    // Cleanup the event listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', updateSystemTheme);
    };
  }, []);

  // Determine which logo to display
  const currentTheme = theme === 'system' ? systemTheme : theme;


  useEffect(() => {
    if (!user) return;

    const userId = user?.user?._id;
    socket.emit('join', userId);

    const fetchNotificationCount = async () => {
      const count = await getNewNotificationsCount();
      if (count !== undefined) {
        setNewNotification(count);
      }
    };

    fetchNotificationCount();

    socket.on('newNotification', async (notification) => {
      console.log('New notification received:', notification);
      setNewNotification(prevCount => {
        const updatedCount = prevCount + 1;
        addNewNotificationsCount(updatedCount);
        return updatedCount;
      });
    });

    return () => {
      socket.off('newNotification');
    };
  }, [user]);

  useEffect(() => {

    if (!location.pathname.startsWith('/b/')) {
      dispatch(setSelectedBackground(''));
    }
  }, [location.pathname]);

  const handleShowProfile = () => setIsShowProfile(prev => !prev);
  const handleShowSearchResult = () => setSearchResult(prev => !prev);

  const handleShowNotifications = () => {
    setIsShowNotifications(prev => !prev);
    setNewNotification(0);
    addNewNotificationsCount(0);
  };

  const handleCreateWorkspace = () => {
    dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
  };


  const handleNavClick = (e, nav) => {
    setActiveNav((prevNav) => (prevNav === nav ? null : nav)); // Toggle active class
    handlePopoverClick(e, nav); // Call the existing handlePopoverClick function
  };

  useOutsideClick(notificationsRef, () => setIsShowNotifications(false));
  useOutsideClick(profileRef, () => setIsShowProfile(false));
  useOutsideClick(searchRef, () => setSearchResult(false));
  useOutsideClick(workspaceRef, () => setActiveNav(null));
  useOutsideClick(recentRef, () => setActiveNav(null));
  useOutsideClick(starredRef, () => setActiveNav(null));

  const addNewNotificationsCount = async (count) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newNotificationsCount: count }),
      });

      if (!response.ok) throw new Error('Failed to add new notification count');
      const data = await response.json();
      console.log('Notification count updated successfully:', data);
    } catch (error) {
      console.error('Error adding notification count:', error);
    }
  };

  const getNewNotificationsCount = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch notification count');
      const data = await response.json();
      return data.newNotificationsCount;
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  // if (userLoading) return null;//<div className='loading'>Loading...</div>;
  if (userError) return <div>Error: {userError}</div>;

  return (
    <header className="zoobbe-header" style={{ backgroundUrl: board?.cover?.coverColor[0] || '' }}>
      {/* <header className="zoobbe-header" style={{backgroundImage: `url(${board?.cover.sizes.medium})` || ''}}> */}

      <div className="header-left">
        <div className="zoobbe-header__logo">
          <Link to="/">

            <p>
              {currentTheme === 'dark' && <Logo />}
              {currentTheme === 'light' && <LogoLight />}
              <sup className="logo-beta">Beta</sup></p>
          </Link>
        </div>

        <ul className='header-nav-content'>
          <li
            className={`nav-workspaces ${activeNav === 'navWorkspaces' ? 'active' : ''}`}
            id='popover-nav-workspaces'
            onClick={(e) => handleNavClick(e, 'navWorkspaces')}
            ref={workspaceRef}
            data-tooltip-content="Workspaces"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <span className="material-symbols-outlined">workspaces</span>
          </li>
          <li
            className={`nav-recent ${activeNav === 'navRecent' ? 'active' : ''}`}
            id='popover-nav-recent'
            onClick={(e) => handleNavClick(e, 'navRecent')}
            ref={recentRef}
            data-tooltip-content="Recent"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <span className="material-symbols-outlined">history_2</span>
          </li>
          <li
            className={`nav-starred ${activeNav === 'navStarred' ? 'active' : ''}`}
            id='popover-nav-starred'
            onClick={(e) => handleNavClick(e, 'navStarred')}
            ref={starredRef}
            data-tooltip-content="Starred"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <span className="material-symbols-outlined">award_star</span>
          </li>

        </ul>
        {/* <ul className='header-nav-content'>
          <li className='nav-workspaces' id='popover-nav-workspaces' onClick={(e) => handlePopoverClick(e, 'navWorkspaces')} data-popover-trigger>
            WorkSpaces
            <span class="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          </li>
          <li className='nav-recent' id='popover-nav-recent' onClick={(e) => handlePopoverClick(e, 'navRecent')} data-popover-trigger>
            Recent
            <span class="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          </li>
          <li className='nav-starred' id='popover-nav-starred' onClick={(e) => handlePopoverClick(e, 'navStarred')} data-popover-trigger>
            Starred
            <span class="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          </li>
        </ul> */}

        {/* {
          user?.user?.verified && (
            <div className="zoobbe-header__menu">
              <button to="/create" onClick={handleCreateWorkspace} className="zoobbe-header__menu-item zoobbe-header__menu-item--create">
                <span className="material-symbols-outlined">add</span>Create
              </button>
            </div>
          )
        } */}
      </div>

      <div className="header-center">
        <div className={`zoobbe-header__search-container ${isShowSearchResult ? 'isOpen' : ''} ${isFocused ? 'focused' : ''}`} ref={searchRef}>
          <div className="search-content">
            <span className="material-symbols-outlined">search</span>
            <input
              className="zoobbe-header__search-input"
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => {
                setIsFocused(true);
                setSearchResult(true);
              }} // Add focused class and show SearchResults
              onBlur={() => setIsFocused(false)} // Remove focused class and hide SearchResults
              autoComplete="off"
            />

          </div>
          {isShowSearchResult && <SearchResults query={query} setQuery={setQuery} setSearchResult={setSearchResult} />}
        </div>
      </div>

      <div className="header-right">

        {/* <div className={`zoobbe-header__search-container ${isShowSearchResult ? 'isOpen' : ''} ${isFocused ? 'focused' : ''}`} ref={searchRef}>
          <div className="search-content">
            <span className="material-symbols-outlined">search</span>
            <input
              className="zoobbe-header__search-input"
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => {
                setIsFocused(true);
                setSearchResult(true);
              }} // Add focused class and show SearchResults
              onBlur={() => setIsFocused(false)} // Remove focused class and hide SearchResults
              autoComplete="off"
            />

          </div>
          {isShowSearchResult && <SearchResults query={query} setQuery={setQuery} setSearchResult={setSearchResult} />}
        </div> */}

        {
          user?.user?.verified && (
            <div className="zoobbe-header__menu">
              <button to="/create" onClick={handleCreateWorkspace} className="zoobbe-header__menu-item zoobbe-header__menu-item--create">
                <span className="material-symbols-outlined">add</span>Create
              </button>
            </div>
          )
        }

        <div className="zoobbe-header__icons">
          <div className="search-container">
            <div className="search-icon">
              <span class="material-symbols-outlined">search</span>
            </div>
          </div>
          <div className="notifications-container" ref={notificationsRef}>
            <div
              className={`notifications-icon${(isShowNotifications || newNotification) ? ' active' : ''}`}
              data-tooltip-content="Notifications"
              data-tooltip-position="bottom"
              onClick={handleShowNotifications}
            >
              <span className="material-symbols-outlined">
                notifications
              </span>
              {newNotification > 0 && <span className='notification-counter'>{newNotification}</span>}
            </div>

            {isShowNotifications && (
              <Notifications setIsShowNotifications={setIsShowNotifications} />
            )}
          </div>

          <div className="profile-container" ref={profileRef}>
            <div className="profile-image" onClick={handleShowProfile}>

              {user?.user?.profilePicture ? (
                <img
                  className={`zoobbe-header__icons-avatar${isShowProfile ? ' active' : ''}`}
                  src={user?.user?.profilePicture}
                  alt={user?.user?.username}
                />
              ) : (
                <ImagePlaceholder
                  key={user?.user?._id}
                  size={22}
                  text={user?.user?.username}
                  className={`${isShowProfile ? ' active' : ''}`}

                />

              )}
            </div>

            {isShowProfile && (
              <ProfileDropdown
                userInfo={user}
                setIsShowProfile={setIsShowProfile}
              />
            )}
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
