import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { fetchStarredBoards } from '../../redux/Slices/boardsSlice';

import './scss/NavRecent.scss';
import Spinner from './Spinner';

const NavStarred = () => {
    const dispatch = useDispatch();
    const { starredBoards, starredStatus } = useSelector((state) => state.boards);

    useEffect(() => {
        if (starredStatus === 'idle') {
            dispatch(fetchStarredBoards());
        }
    }, [starredStatus, dispatch]);

    return (
        <div className='nav-recent__search-results'>
            {starredStatus == 'loading' ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Spinner size={30} color="#3498db" speed={1.5} />
                </div>
            ) : (
                starredBoards?.length > 0 && (
                    <section className="category">
                        {starredBoards.map(board => (
                            <div
                                className="result-item"
                                key={board._id}
                            >
                                {board.cover?.sizes?.thumbnail ? (
                                    <div
                                        className="icon board-icon"
                                        style={{ backgroundImage: `url(${board.cover?.sizes?.medium})` }}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.625 6.4 2.8-3.625q.3-.4.712-.587Q11.55 1.999 12 2q.45 0 .863.188.412.186.712.587l2.8 3.625 4.25 1.425q.65.2 1.025.737.375.538.375 1.188 0 .3-.087.6-.089.3-.288.575l-2.75 3.9.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25a1 1 0 0 1-.275.063Q7.1 21 6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125-2.725-3.875a1.9 1.9 0 0 1-.288-.575Q2 10.05 2 9.75q0-.625.362-1.162.363-.538 1.013-.763zM17 19 4 9.725l16 .05-13 9.2L12 4zL4 9.725l16 .05-13 9.2L12 4z" fill="#FFD700"></path></svg>
                                    </div>
                                ) : (
                                    <div className="icon card-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.625 6.4 2.8-3.625q.3-.4.712-.587Q11.55 1.999 12 2q.45 0 .863.188.412.186.712.587l2.8 3.625 4.25 1.425q.65.2 1.025.737.375.538.375 1.188 0 .3-.087.6-.089.3-.288.575l-2.75 3.9.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25a1 1 0 0 1-.275.063Q7.1 21 6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125-2.725-3.875a1.9 1.9 0 0 1-.288-.575Q2 10.05 2 9.75q0-.625.362-1.162.363-.538 1.013-.763zM17 19 4 9.725l16 .05-13 9.2L12 4zL4 9.725l16 .05-13 9.2L12 4z" fill="#FFD700"></path></svg>
                                    </div>
                                )}
                                <div className="content">
                                    <p className="title">{board.title}</p>
                                    {/* <p className="subtitle">{board.workspace.name}</p> */}
                                </div>
                                <Link className="card-permalink" to={board.permalink}></Link>
                            </div>
                        ))}
                    </section>
                )
            )}
        </div>
    );
};

export default NavStarred;
