import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackBar, showSnackBar } from '../../../redux/Slices/snackbarSlice';
import { config } from '../../../config';
import { fetchBoardById, setSelectedBackground } from '../../../redux/Slices/boardSlice';

import Spinner from '../../Global/Spinner';

// Debounce utility function
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

const Unsplash = () => {
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [unsplashImages, setUnsplashImages] = useState([]);
    const [isLoadingUnsplash, setIsLoadingUnsplash] = useState(false);
    const [page, setPage] = useState(1); // Current page for Unsplash API
    const [hasMoreImages, setHasMoreImages] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // Track selected image
    const [isSetSaving, setIsSaving] = useState(false);
    const [isSaved, setSaved] = useState(false);
    const { status } = useSelector((state) => state.attachments);
    const { board } = useSelector((state) => state.board);

    const urlRef = useRef(null);
    const observer = useRef();

    const boardId = board.shortId;
    const dispatch = useDispatch();

    useEffect(() => {
        if (urlRef.current) {
            urlRef.current.style.height = '22px';
            urlRef.current.style.height = `${urlRef.current.scrollHeight}px`;
        }
    }, [attachmentUrl]);

    useEffect(() => {
        if (status === 'uploadSucceeded') {
            dispatch(hideSnackBar());
        } else if (status === 'uploadFailed') {
            dispatch(showSnackBar({
                message: 'File upload failed',
                type: 'error',
            }));
        }
    }, [status, dispatch, boardId]);

    // Fetch Unsplash images
    useEffect(() => {
        if (page > 0) fetchUnsplashImages(page);
    }, [page]);

    const fetchUnsplashImages = async (page) => {
        if (!hasMoreImages) return;
        setIsLoadingUnsplash(true); // Show spinner

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/unsplash?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch images: ${response.statusText}`);
            }

            const data = await response.json();
            if (!data.success || !Array.isArray(data.photos)) {
                throw new Error('Unexpected response format from the server');
            }

            setUnsplashImages((prevImages) => [...prevImages, ...data.photos]);
            setHasMoreImages(data.photos.length > 0); // Disable loading when no more images
        } catch (error) {
            console.error('Error fetching Unsplash images:', error);
        } finally {
            setIsLoadingUnsplash(false); // Hide spinner
        }
    };

    const lastImageRef = useCallback((node) => {
        if (isLoadingUnsplash) return; // Avoid setting up the observer during loading
        if (observer.current) observer.current.disconnect(); // Disconnect previous observer

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreImages) {
                setPage((prevPage) => prevPage + 1); // Increment page when the last image is visible
            }
        });

        if (node) observer.current.observe(node); // Observe the last image node
    }, [isLoadingUnsplash, hasMoreImages]);

    const debouncedHandleUnsplashImageSelect = useCallback(
        debounce(async (url) => {
            // dispatch(showSnackBar({ message: 'Setting background...', type: 'uploading' }));
            setIsSaving(true);

            try {
                const token = localStorage.getItem('accessToken');

                if (!token) {
                    throw new Error('User is not authenticated. Access token is missing.');
                }

                const response = await fetch(`${config.API_URI}/api/boards/boards-background`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ url, boardId }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to set background: ${response.status} ${errorText}`);
                }

                setIsSaving(false);
                setSaved(true);

            } catch (error) {
                console.error('Error setting background:', error);
                dispatch(showSnackBar({ message: 'Failed to set background', type: 'error' }));
            }
        }, 0), // Debounce with a 500ms delay
        [boardId, dispatch]
    );

    const handleImageSelect = (url) => {
        setSelectedImage(url);
        setSaved(false);
        dispatch(setSelectedBackground(url));
    };

    const handleSaveBackground = () => {
        if (selectedImage) {
            debouncedHandleUnsplashImageSelect(selectedImage); // Save selected image as background
        } else {
            dispatch(showSnackBar({ message: 'No image selected', type: 'error' }));
        }
    };

    return (
        <div className="add-attachment">
            <div className="group">
                <h3 className='background-group-title'>Choose an image from <a target='_blank' href='https://unsplash.com/?utm_source=zoobbe&utm_medium=referral&utm_campaign=api-credit'>Unsplash</a></h3>

                <div className="unsplash-background-images">
                    <div className='save-unsplash-background' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <button
                            onClick={handleSaveBackground}
                            className={isSetSaving || !selectedImage || isSaved ? 'full-deactivated' : ''}>
                            {isSetSaving
                                ? 'Saving'
                                : isSaved
                                    ? 'Saved Background'
                                    : 'Save Background'}
                        </button>


                    </div>
                    <div className="unsplash-images">
                        {unsplashImages.map((img, index) => {
                            const isLastImage = index === unsplashImages.length - 1;
                            const isSelected = img.urls.regular === selectedImage; // Check if the image is selected

                            return (
                                <div
                                    className={`board-background-select${isSelected ? ' selected' : ''}`}
                                    key={img.id}
                                    ref={isLastImage ? lastImageRef : null}
                                >
                                    <span
                                        className={`background-box`}
                                        data-testid={`board-background-select-photo-${index}`}
                                        style={{ backgroundImage: `url(${img.urls.thumb})` }}
                                        onClick={() => handleImageSelect(img.urls.regular)}
                                    >
                                        {isSelected && <span className="material-icons checkmark">check</span>} {/* Display check mark */}
                                        <div className="large">
                                            <a
                                                href={`${img.user.links.html}?utm_source=zoobbe&utm_medium=referral&utm_campaign=api-credit`}
                                                target="_blank"
                                                title={img.user.name}
                                            >
                                                {img.user.name}
                                            </a>
                                        </div>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {isLoadingUnsplash && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '80px', marginTop: '5px' }}>
                            <Spinner size={30} color="#3498db" speed={1.5} />
                        </div>
                    )}

                </div>

            </div>
        </div>
    );
};

export default Unsplash;
