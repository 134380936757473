import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '../../redux/Slices/modalSlice';
import { fetchWorkspaces, fetchGuestsWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';
import { ActionTypes } from '../../sockets/ActionTypes';

import WorkspaceSidebar from './WorkspaceSidebar';
import BoardSection from './BoardSection';
import RecentView from './RecentView';
import TopBoards from './TopBoards';
import Spinner from '../Global/Spinner';

import './scss/workspace.scss';
import { find } from '../../utils/helpers';
import { fetchStarredBoards, fetchRecentBoards } from '../../redux/Slices/boardsSlice';
import MetaTags from '../Global/MetaTags';

const WorkSpace = () => {
    const { workspaces, guestWorkspaces, status, guestStatus, error } = useSelector(state => state.workspaces);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [openWorkspaces, setOpenWorkspaces] = useState({});
    const lastFetchTimeRef = useRef(Date.now()); // Track last fetch time

    const { recentBoards, cardCount } = useSelector((state) => state.boards);


    const { starredBoards, starredStatus } = useSelector((state) => state.boards);

    useEffect(() => {
        if (starredStatus === 'idle') {
            dispatch(fetchStarredBoards());
        }
    }, [starredStatus, dispatch]);


    useEffect(() => {
        dispatch(fetchRecentBoards({ limit: 10 }));
    }, [dispatch]);



    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    useEffect(() => {
        if (!user) return;

        const userId = user?.user?._id;
        const username = user.user.username;
        const socket = socketIOClient(config.API_URI);

        socket.emit('joinUser', userId, username);

        socket.on('user-action', (data) => {
            const now = Date.now();
            if (now - lastFetchTimeRef.current > 5000 && status !== 'loading') { // Fetch only if idle
                switch (data.action) {
                    case ActionTypes.MEMBER_ADDED:
                    case ActionTypes.MEMBER_REMOVED:
                    case ActionTypes.ROLE_CHANGED:
                    case ActionTypes.BOARD_CREATED:
                    case ActionTypes.BOARD_UPDATED:
                        dispatch(fetchWorkspaces());
                        dispatch(fetchGuestsWorkspaces());
                        lastFetchTimeRef.current = now;
                        break;
                    default:
                        console.log('Unhandled user action:', data.action);
                }
            }
        });


        return () => {
            socket.disconnect();
        };
    }, [dispatch, user]);


    return (
        <>
            <div className="zppbbe-workspace-wrapper">
                <div className="zoobbe-workspace-container">
                    <aside className="zoobbe-sidebar-container">
                        <WorkspaceSidebar
                            openWorkspaces={openWorkspaces}
                            toggleOptions={toggleOptions}
                            handleCreateWorkspace={handleCreateWorkspace}
                        />
                    </aside>

                    <div className="zoobbe-main-content">

                        {
                            starredBoards?.length > 0 && (
                                <div className="zoobbe-recently-viewed">
                                    <section className="zoobbe-your-workspaces">
                                        <h2>
                                            <span className="material-symbols-outlined">
                                                award_star
                                            </span>Starred Boards</h2>
                                        <div className="zoobbe-workspace-section">
                                            <TopBoards type={'STARRED'} boards={starredBoards} />
                                        </div>

                                    </section>
                                </div>
                            )
                        }
                        {
                            recentBoards?.length > 0 && (
                                <div className="zoobbe-recently-viewed">
                                    <section className="zoobbe-your-workspaces">
                                        <h2>
                                            <span className="material-symbols-outlined">
                                                history_2
                                            </span>Recently viewed</h2>
                                        <div className="zoobbe-workspace-section">
                                            <RecentView />
                                        </div>

                                    </section>
                                </div>
                            )
                        }


                        {
                            ((status === 'loading' || guestStatus === 'loading') && workspaces?.length < 1) ? (
                                // Show a loading spinner while loading
                                <div className="spinner-container">
                                    <Spinner />
                                </div>
                            ) : workspaces?.length > 0 ? (
                                // Show workspaces if available
                                <section className="zoobbe-your-workspaces">
                                    <h2>Your Workspaces</h2>
                                    {workspaces.map((workspace, index) => (
                                        <div className="zoobbe-workspace-section" key={workspace._id}>
                                            <div className="boards-page-board-section-header">
                                                <div className="zoobbe-workspace-card">
                                                    <span className="zoobbe-workspace-icon material-symbols-outlined">
                                                        workspaces
                                                    </span>
                                                    <span className="zoobbe-workspace-title">{workspace.name}</span>
                                                </div>
                                                <div className="zoobbe-workspace-actions">
                                                    <Link to={`/w/${workspace.shortId}/boards`} data-tooltip-content="Boards" data-tooltip-position="top">
                                                        <span className="material-symbols-outlined">developer_board</span>
                                                    </Link>
                                                    <Link to={`/w/${workspace.shortId}/members`} data-tooltip-content="Members" data-tooltip-position="top">
                                                        <span className="material-symbols-outlined">groups_2</span> ({workspace.members.length})
                                                    </Link>
                                                    <Link to={`/w/${workspace.shortId}/settings`} data-tooltip-content="Settings" data-tooltip-position="top">
                                                        <span className="material-symbols-outlined">settings</span>
                                                    </Link>

                                                    {
                                                        !config.IS_BETA && (
                                                            <Link to={`/w/${workspace.shortId}/upgrade`}>Upgrade</Link>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <BoardSection workspace={workspace} defaultSelectedOption={index} />
                                        </div>
                                    ))}
                                </section>
                            ) : ((status === 'succeeded' && guestStatus === 'succeeded') && workspaces?.length < 1) && (
                                <div style={{ textAlign: 'center', padding: '40px', maxWidth: '520px' }}>
                                    <span
                                        className="material-icons"
                                        style={{
                                            fontSize: '120px',
                                            color: '#9E9E9E',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        assignment_add
                                    </span>

                                    <p style={{ fontSize: '16px', color: '#757575', marginBottom: '20px' }}>
                                        You don't have any workspace. Workspaces help you organize tasks and collaborate with your team. Create your first workspace to get started!
                                    </p>

                                    <button
                                        onClick={handleCreateWorkspace}
                                        style={{
                                            padding: '10px 20px',
                                            fontSize: '16px',
                                            color: '#fff',
                                            backgroundColor: '#007BFF',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            transition: 'background-color 0.3s, box-shadow 0.3s',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.backgroundColor = '#0056b3';
                                            e.target.style.boxShadow = '0 6px 8px rgba(0, 0, 0, 0.2)';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.backgroundColor = '#007BFF';
                                            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                                        }}
                                    >
                                        Create Workspace
                                    </button>
                                </div>

                            )
                        }


                        {
                            guestStatus !== 'loading' && guestWorkspaces.length > 0 && (
                                <section className="zoobbe-guest-workspaces">
                                    <h2>Guest Workspaces</h2>
                                    {guestWorkspaces.map((workspace, defaultSelectedOption) => (
                                        <div className="zoobbe-workspace-section" key={workspace._id}>
                                            <div className='boards-page-board-section-header'>
                                                <div className="zoobbe-workspace-card">
                                                    <span className="zoobbe-workspace-icon">{workspace.name ? workspace.name.charAt(0) : ''}</span>
                                                    <span className="zoobbe-workspace-title">{workspace.name}</span>
                                                </div>
                                            </div>

                                            <BoardSection workspace={workspace} defaultSelectedOption={defaultSelectedOption} isGuests={true} />

                                        </div>

                                    ))}

                                    {
                                        status === 'loading' && (workspaces?.length === 0 || guestWorkspaces?.length === 0) && (
                                            <div className='spinner-container'>
                                                <Spinner />
                                            </div>
                                        )
                                    }

                                </section>
                            )
                        }
                    </div>
                </div >
            </div >

        </>

    );
};

export default WorkSpace;
