import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { openModal } from '../../redux/Slices/modalSlice';

import { find, getOptionIndex, uniqid } from '../../utils/helpers';

import ImagePlaceholder from '../Global/ImagePlaceholder';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

import './scss/Collaborators.scss';
import ShareBoardModal from '../Workspaces/ShareBoard';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import Spinner from '../Global/Spinner';


const Collaborators = () => {

  const [activeTab, setActiveTab] = useState('workspace-members');
  const [filterText, setFilterText] = useState('');
  const [selectedRole, setSelectedRole] = useState({ value: 'member', label: 'Member' });

  const { handlePopoverClick } = useHandlePopoverClick();
  const dispatch = useDispatch();

  const { workspaces } = useSelector(state => state.workspaces);
  const { shortId } = useParams('shortId');
  const workspaceId = shortId;

  useEffect(() => {
    if (workspaces.length === 0) {
      dispatch(fetchWorkspaces()).unwrap().then((fetchedWorkspaces) => {
        if (!fetchedWorkspaces || fetchedWorkspaces.length === 0) {
          console.log('Failed to load workspaces or user has no workspaces');
        }
      }).catch((error) => {
        console.error('Error fetching workspaces:', error);
      });
    }
  }, [dispatch, workspaces.length]);

  useEffect(() => {
    dispatch(fetchWorkspaces());
  }, [dispatch, workspaceId]);


  // const collaborators = find.get(workspaces, { name: 'members', shortName: workspaceId }) || [];

  const collaborators = useSelector(state => state.member.workspaceMembers);
  const status = useSelector(state => state.member.status);
  const guests = useSelector(state => state.member.workspaceGuests);

  const totalAdmins = collaborators.filter(m => m.role === 'admin').length;

  const workspace = find.get(workspaces, { name: 'workspace', workspace: workspaceId }) || {};

  const workspaceName = workspace.name || '';

  useEffect(() => {
    dispatch(fetchMembers({ type: 'workspace', id: workspaceId }));
    dispatch(fetchMembers({ type: 'guests', id: workspaceId }));
  }, [dispatch, workspaceId]);

  const handleInviteClick = () => {
    dispatch(openModal({ modalType: 'INVITE_WORKSPACE', modalData: { workspaceId } }));
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const removeMemberFromWorkspace = async (e, member) => {
    e.preventDefault();

    const { _id, role } = member;

    if (totalAdmins <= 1 && role === 'admin') {
      dispatch(showToast({ message: 'You can\'t remove only one admin ', type: 'error' }));
      return;
    }

    if (!workspaceId) {
      dispatch(showToast({ message: 'Workspace ID is empty', type: 'error' }));
      return;
    }

    const isConfirmed = window.confirm('Are you sure you want to remove this member from the workspace?');
    if (!isConfirmed) {
      return;
    }

    // console.log(workspaceId, memberId);

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/workspace/${workspaceId}/member`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          workspaceId,
          memberId: _id,
        }),
      });

      const data = await response.json();
      const messageType = response.status === 200 ? 'success' : 'error';

      dispatch(showToast({ message: data.message, type: messageType }));
      if (response.status === 200) {
        dispatch(fetchWorkspaces());
        dispatch(fetchMembers({ type: 'workspace', id: workspaceId }));
        dispatch(fetchMembers({ type: 'guests', id: workspaceId }));
      }
    } catch (error) {
      console.error('Error removing member:', error);
      dispatch(showToast({ message: 'Error removing member', type: 'error' }));
    }
  };

  const updateMemberRole = async (newRole, member) => {

    const { _id } = member;

    if (!workspaceId || !_id) {
      dispatch(showToast({ message: 'Workspace Id or Member Id is empty', type: 'error' }));
      return;
    }

    try {
      const token = localStorage.getItem('accessToken');

      // Prepare the request payload
      const payload = {
        memberId: _id, // ID of the member to update
        newRole: newRole.value,
        workspaceId: workspaceId
      };

      const response = await fetch(`${config.API_URI}/api/workspace/${workspaceId}/member`, {
        method: 'PUT', // Use PUT method for role update
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      const messageType = response.status === 200 ? 'success' : 'error';

      dispatch(showToast({ message: data.message, type: messageType }));
      if (response.status === 200) {
        dispatch(fetchMembers({ type: 'workspace', id: workspaceId }));
        dispatch(fetchMembers({ type: 'guests', id: workspaceId }));
      }
    } catch (error) {
      console.error('Error updating member role:', error);
      dispatch(showToast({ message: 'Error updating member role', type: 'error' }));
    }
  };


  const roleOptions = [
    { value: 'member', label: 'Member' },
    { value: 'guest', label: 'Guest' },
    { value: 'admin', label: 'Admin' }
  ];

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const renderTabContent = () => {
    if (activeTab === 'workspace-members') {
      const filteredCollaborators = collaborators.filter(member =>
        member?.username?.toLowerCase().includes(filterText?.toLowerCase())
      );

      return (
        <div className="workspace-members">
          <h3>Workspace members ({filteredCollaborators.length}) <span className="icon-copy" /></h3>
          <p>Workspace members can view and join all Workspace visible boards and create new boards in the Workspace. Adding new members will automatically update your billing.</p>
          <div className="invite">
            <h4>Invite members to join you</h4>
            <p>Anyone with an invite link can join this paid Workspace. You’ll be billed for each member that joins. You can also disable and create a new invite link for this Workspace at any time.</p>
            <button className="invite-link-button" onClick={handleInviteClick}>
              <span className="material-symbols-outlined">
                group
              </span>Invite Workspace members
            </button>
          </div>

          <div className="members-list">
            <input
              type="text"
              placeholder="Filter by name"
              value={filterText}
              onChange={handleFilterChange}
            />
            {status === 'loading' ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                <Spinner size={30} color="#3498db" speed={1.5} />
              </div>
            ) : (
              filteredCollaborators.map((member, index) => {
                const handleRoleChange = (newRole) => {
                  if (member.role === 'admin' && newRole.value !== 'admin' && totalAdmins <= 1) {
                    dispatch(showToast({ message: 'Cannot remove the last admin. At least one admin is required.', type: 'error' }));
                    return;
                  }
                  updateMemberRole(newRole, member);
                  handleRoleSelect(newRole, member._id);
                };

                // Determine if this member is the last admin
                const isLastAdmin = totalAdmins <= 1 && member.role === 'admin';

                return (
                  <div className="member" key={member.username}>
                    {member.profilePicture ? (
                      <img src={member.profilePicture} alt={member.username} className="profile-pic" />
                    ) : (
                      <ImagePlaceholder size={40} text={member.username} fontSize='14px' />
                    )}

                    <div className="member-info">
                      <div className="member-name">{member.name}</div>
                      <div className="member-bottom">
                        <span className="username">@{member.username}</span>
                        •
                        <span className="last-active">Last active {member.lastActive}</span>
                      </div>
                    </div>

                    <button
                      className={`view-boards-button ${member.boardCount === 0 ? 'full-deactivated' : ''}`}
                      id={`popover-board-list-${member._id}`}
                      onClick={(e) => handlePopoverClick(e, 'popoverBoardList', { member, workspaceId })}
                      data-popover-trigger
                    >
                      View boards ({member.boardCount})
                    </button>

                    <div className="admin-actions">
                      <ZoobbeSelect
                        options={roleOptions}
                        defaultSelectedOption={getOptionIndex(member.role, roleOptions)}
                        onSelect={handleRoleChange}
                        className={` admin-role${isLastAdmin ? ' full-deactivated' : ''}`}
                        isDisabled={isLastAdmin} // Disable the role select if last admin
                      />
                      <button
                        className={`leave-button${isLastAdmin ? ' full-deactivated' : ''}`}
                        onClick={(e) => removeMemberFromWorkspace(e, member)}
                        disabled={isLastAdmin} // Disable the remove button if last admin
                      >
                        <span className="material-symbols-outlined">close</span>
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })

            )}
          </div>
        </div>
      );
    }

    if (activeTab === 'guests') {
      const filteredGuests = guests.filter(guest =>
        guest?.username?.toLowerCase().includes(filterText?.toLowerCase())
      );

      return (
        <div className="workspace-members">
          <h3>Workspace guests ({filteredGuests.length}) <span className="icon-copy" /></h3>
          <p>Workspace members can view and join all Workspace visible boards and create new boards in the Workspace. Adding new members will automatically update your billing.</p>
          <div className="invite">
            <h4>Invite members to join you</h4>
            <p>Anyone with an invite link can join this paid Workspace. You’ll be billed for each member that joins. You can also disable and create a new invite link for this Workspace at any time.</p>
            <button className="invite-link-button" onClick={handleInviteClick}>
              <span className="material-symbols-outlined">
                group
              </span>Invite Workspace members
            </button>
          </div>

          <div className="members-list">
            <input
              type="text"
              placeholder="Filter by name"
              value={filterText}
              onChange={handleFilterChange}
            />
            {filteredGuests.map(member => {

              const handleRoleChange = (newRole) => {

                if (member.role === 'admin' && newRole.value !== 'admin' && totalAdmins <= 1) {
                  dispatch(showToast({ message: 'Cannot remove the last admin. At least one admin is required.', type: 'error' }));
                  return;
                }
                updateMemberRole(newRole, member)
                handleRoleSelect(newRole, member._id);
              };

              return (
                <div className="member" key={member.username}>
                  {
                    member.profilePicture ? (
                      <img src={member.profilePicture} alt={member.username} className="profile-pic" />
                    ) : (
                      <ImagePlaceholder size={40} text={member.username} fontSize='14px' />
                    )
                  }

                  <div className="member-info">
                    <div className="member-name">{member.name}</div>
                    <div className="member-bottom">
                      <span className="username">@{member.username}</span>
                      •
                      <span className="last-active">Last active {member.lastActive}</span>
                    </div>
                  </div>
                  <button
                    className="view-boards-button"
                    id={`popover-board-list-${member._id}`}
                    onClick={(e) => handlePopoverClick(e, 'popoverBoardList', { member, workspaceId })}
                    data-popover-trigger
                  >
                    View boards ({member.boardCount})
                  </button>
                  <div className="admin-actions">
                    <ZoobbeSelect
                      options={roleOptions}
                      defaultSelectedOption={getOptionIndex(member.role, roleOptions)}
                      onSelect={handleRoleChange}
                      className={' admin-role'}
                    />
                    <button className="leave-button" onClick={(e) => removeMemberFromWorkspace(e, member)}>
                      <span className="material-symbols-outlined">
                        close
                      </span>
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null; // For other tabs (e.g., join requests)
  };

  return (
    <div className="zoobbe-main-content">
      <div className="collaborators">
        <div className='tab-content'>
          <h2>Collaborators (3)</h2>
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'workspace-members' ? 'selected' : ''}`}
              onClick={() => setActiveTab('workspace-members')}
            >
              Workspace members ({collaborators.length})
            </div>
            <div
              className={`tab ${activeTab === 'guests' ? 'selected' : ''}`}
              onClick={() => setActiveTab('guests')}
            >
              Guests ({guests.length})
            </div>
            <div
              className={`tab ${activeTab === 'join-requests' ? 'selected' : ''}`}
              onClick={() => setActiveTab('join-requests')}
            >
              Join requests (0)
            </div>
          </div>
        </div>

        <div className="collaborators-content">
          <div className="board-header">
            <div className="board-icon">
              <span className="icon-text">Z</span>
            </div>
            <div className="board-details">
              <div className="board-name">
                <span>{workspaceName}</span>
                <span className="material-symbols-outlined">
                  edit
                </span>
              </div>
              <div className="board-visibility">
                <span className="material-symbols-outlined">
                  lock
                </span>
                <span>Private</span>
              </div>
            </div>
          </div>

          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default Collaborators;
