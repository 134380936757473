import React from 'react';
import AddChecklist from '../../components/WindowSidebar/AddChecklist';
import AddLabels from '../../components/WindowSidebar/AddLabels';
import AddDueDate from '../../components/WindowSidebar/AddDueDate';
import ProfileCard from '../../components/Global/ProfileCard';
import PopoverBoardList from '../../components/Profile/PopoverBoardList';
import MembersPopover from '../../components/Workspaces/MembersPopover';
import MoveCard from '../../components/WindowSidebar/moveCard';
import ShareCard from '../../components/WindowSidebar/ShareCard';
import DeleteCard from '../../components/WindowSidebar/DeleteCard';
import Attachment from '../../components/WindowSidebar/Attachment';
import CopyCard from '../../components/WindowSidebar/CopyCard';
import DeleteAction from '../../components/Global/DeleteAction';
import More from '../../components/Global/More.js';
import MemberRole from '../../components/Global/MemberRole.js';
import BoardVisibility from '../../components/Workspaces/BoardVisibility.js';
import Feedback from '../../components/Global/Feedback.js';
import MoreMembers from '../../components/Global/MoreMembers.js';
import NavRecent from '../../components/Global/NavRecent.js';
import NavStarred from '../../components/Global/NavStarred.js';
import NavWorkspaces from '../../components/Global/NavWorkspaces.js';
import Filter from '../../components/Workspaces/Filter.js';

const popover = {
    addChecklist: (props) => <AddChecklist {...props} />,
    addLabels: (props) => <AddLabels {...props} />,
    addDueDate: (props) => <AddDueDate {...props} />,
    memberInfo: (props) => <ProfileCard {...props} />,
    cardMemberesPopover: (props) => <MembersPopover {...props} />,
    popoverBoardList: (props) => <PopoverBoardList {...props} />,
    moveCard: (props) => <MoveCard {...props} />,
    shareCard: (props) => <ShareCard {...props} />,
    deleteCard: (props) => <DeleteCard {...props} />,
    attachment: (props) => <Attachment {...props} />,
    copyCard: (props) => <CopyCard {...props} />,
    deleteAction: (props) => <DeleteAction {...props} />,
    editorAttach: (props) => <Attachment {...props} />,
    more: (props) => <More {...props} />,
    memberRole: (props) => <MemberRole {...props} />,
    moreMembers: (props) => <MoreMembers {...props} />,
    boardVisibility: (props) => <BoardVisibility {...props} />,
    navRecent: (props) => <NavRecent {...props} />,
    navStarred: (props) => <NavStarred {...props} />,
    navWorkspaces: (props) => <NavWorkspaces {...props} />,
    filter: (props) => <Filter {...props} />,
    feedBack: (props) => <Feedback {...props} />
    // Add more mappings as needed
};

export const getContentById = (id, props) => popover[id] ? popover[id](props) : null;
