import React from 'react';
import { useDispatch } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { memberInfo } from '../../redux/Slices/memberSlice';
import ImagePlaceholder from './ImagePlaceholder';
import { uniqid } from '../../utils/helpers';

const MoreMembersImage = ({ cardId, members, size, type, displayMembersLimit = 3 }) => {
    const dispatch = useDispatch();

    console.log({members});

    

    // Separate members based on workspaceRole
    const workspaceAdmins = members.filter(member => member.workspaceRole === 'admin');
    const workspaceGuests = members.filter(member => member.workspaceRole === 'guest');
    const workspaceMembers = members.filter(member => member.workspaceRole === 'member');

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, targetId: target.id }));
        dispatch(memberInfo({ member }));
    };

    const renderMembers = (membersList) => (
        membersList?.map((member, index) => {
            if (!member._id) return null;

            return (
                member.profilePicture ? (
                    <span
                        id={`member-info-${cardId || uniqid()}-${member._id}`}
                        className='avatar'
                        key={index}
                        onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                        data-popover-trigger
                    >
                        <img style={{ width: size, height: size }} src={member.profilePicture} alt={member.name} />
                    </span>
                ) : (
                    <span
                        id={`member-info-${cardId || uniqid()}-${member._id}`}
                        className='avatar'
                        key={index}
                        onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                        data-popover-trigger
                    >
                        <ImagePlaceholder size={size} text={member.username} />
                    </span>
                )
            );
        })
    );

    return (
        <div className='more-members-info'>
            {
                workspaceAdmins.length > 0 && (
                    <div className='workspace-members'>
                        <h3>Workspace admins</h3>
                        <div className='board-members'>
                            {renderMembers(workspaceAdmins)}
                        </div>
                    </div>
                )
            }

            {
                workspaceMembers.length > 0 && (
                    <div className='workspace-members'>
                        <h3>Workspace members</h3>
                        <div className='board-members'>
                            {renderMembers(workspaceMembers)}
                        </div>
                    </div>
                )
            }

            {
                workspaceGuests.length > 0 && (
                    <div className='workspace-guests'>
                        <h3>Workspace guests</h3>
                        <div className='board-members guests'>
                            {renderMembers(workspaceGuests)}
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default MoreMembersImage;
