import React, { useEffect, useRef, useState } from "react";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';


import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS, $convertToMarkdownString } from "@lexical/markdown";
import { HashtagNode } from '@lexical/hashtag';
import NewMentionsPlugin from "./plugins/MentionsPlugin";
import ImagesPlugin from './plugins/ImagesPlugin';
import { MentionNode } from "./nodes/MentionNode";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import "./style.scss";
import ActionsPlugin from "./plugins/ActionsPlugin";
import { ImageNode } from "./nodes/ImageNode";
import useOutsideClick from "../../hooks/useOutsideClick";


import {
    createEditor,
    $getRoot,
    $getSelection,
    $createParagraphNode,
    $createTextNode,
    $insertNodes
} from "lexical";
import { marked } from "marked";


function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}

export default function Editor(props) {
    const {
        value,
        setDescription,
        setIsEditingDescription,
        handleUpdateCard,
        boardId
    } = props;

    const lexicalRef = useRef(null);
    const containerRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const description = marked(value || '');

    useEffect(() => {
        // Automatically focus the editor when it is opened
        if (lexicalRef.current) {
            lexicalRef.current.focus();
        }
    }, [isFocused]);


    function prepopulatedRichText(editor) {
        const root = $getRoot();
        const textHtmlMimeType = 'text/html';

        if (root.getFirstChild() === null && description) {
            const parser = new DOMParser();
            const dom = parser.parseFromString(description, textHtmlMimeType);
            const nodes = $generateNodesFromDOM(editor, dom);

            root.append(...nodes);
        }
    }


    const editorConfig = {
        theme: ExampleTheme,
        onError(error) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            HashtagNode,
            MentionNode,
            ImageNode
        ],
        editorState: prepopulatedRichText,
    };


    const handleFocus = () => {
        setIsFocused(true);
        console.log('Editor focused');
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    useOutsideClick(containerRef, () => {
        setIsFocused(false);
    });

    const onChange = (editorState, editor) => {
        editorState.read(() => {
            let html = $generateHtmlFromNodes(editor);

            if (html === '<p className="editor-paragraph"><br></p>') {
                setDescription('')
            }
            else {
                setDescription(html)
            }

        })
    }

    return (
        <>
            <LexicalComposer
                initialConfig={editorConfig}
            >
                <div
                    className={`editor-container${isFocused ? ' editor-focused' : ''}`}
                    ref={containerRef}
                >
                    <ToolbarPlugin />
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable
                                    className="editor-input"
                                    ref={lexicalRef}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}

                                />
                            }
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <OnChangePlugin
                            onChange={onChange}
                        />
                        <HistoryPlugin />
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <HashtagPlugin />
                        <NewMentionsPlugin boardId={boardId} />
                        <ImagesPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    </div>
                    <ActionsPlugin />
                </div>
            </LexicalComposer>

            <div className='editor-action-buttons'>
                <button className='save-editing-content' onClick={() => { handleUpdateCard(); setIsEditingDescription(false); }}>Save</button>
                <button className='cancel-editing-content' onClick={() => setIsEditingDescription(false)}>Discard</button>
            </div>
        </>


    );
}
