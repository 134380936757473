import React, { useState, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Board from './Board';
import CardDetails from './CardDetails';

import './scss/index.scss';

function BoardWrapper() {
    const location = useLocation();
    const isCardPath = location.pathname.startsWith('/c/');

    const [boardState, setBoard] = useState({ actionLists: [] });
    const { board, isLoading } = useSelector((state) => state.board);
    const { selectedBackgroundUrl } = useSelector((state) => state.board);

    const dispatch = useDispatch();

    const { targetListId, targetPosition, card, cardAction } = useSelector((state) => state.moveCard);

    useEffect(() => {
        setBoard((prevBoard) => {
            let cardToProcess = null;

            // Iterate through actionLists to remove or find the card
            const updatedActionLists = prevBoard.actionLists.map((list) => {
                const newList = { ...list };

                if (cardAction === 'MOVE' && newList.cards.some((c) => c._id === card._id)) {

                    newList.cards = newList.cards.filter((c) => {
                        if (c._id === card._id) {
                            cardToProcess = { ...c, actionList: { _id: targetListId } };
                            return false; // Remove the card
                        }
                        return true; // Keep other cards
                    });
                } else if (list._id === card.actionList._id) {
                    cardToProcess = { ...card, actionList: { _id: targetListId } }; // For "copy", clone the card
                }

                return newList;
            });

            // If no card to process, return the previous state
            if (!cardToProcess) {
                // console.error("Card not found during local update");
                return prevBoard;
            }

            // Add the card to the target list
            const finalActionLists = updatedActionLists.map((list) => {
                if (list._id === targetListId) {
                    const newCards = [...list.cards];
                    const position = Math.min(targetPosition, newCards.length); // Ensure position is within bounds
                    newCards.splice(position, 0, cardToProcess);

                    return {
                        ...list,
                        cards: newCards.map((card, index) => ({
                            ...card,
                            order: index, // Recalculate order
                        })),
                    };
                }
                return list;
            });

            // Return the updated board state
            return {
                ...prevBoard,
                actionLists: finalActionLists,
            };
        });
    }, [targetListId, targetPosition, card, cardAction]);


    return (
        <div
            id="zoobbe-board"
            style={{
                backgroundImage: isLoading || !board?.cover?.url ? 'none' : `url(${selectedBackgroundUrl || board.cover.url})`,
            }}
        >
            <Board board={boardState} setBoard={setBoard} />
            {isCardPath && <CardDetails board={boardState} setBoard={setBoard} />}
        </div>
    );
}

export default BoardWrapper;
