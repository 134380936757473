import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { fetchRecentBoards } from '../../redux/Slices/boardsSlice';

import './scss/NavRecent.scss';
import Spinner from './Spinner';
import StarFill from '../icons/StarFill';

const NavRecent = () => {
    const dispatch = useDispatch();

    const { recentBoards, recentStatus } = useSelector((state) => state.boards) || [];
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if (recentBoards.length < 1) {
            dispatch(fetchRecentBoards({ limit: 10 }));
        }
    }, [dispatch]);

    return (
        <div className='nav-recent__search-results'>
            {recentStatus == 'loading' ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Spinner size={30} color="#3498db" speed={1.5} />
                </div>
            ) : (
                recentBoards?.length > 0 && (
                    <section className="category">
                        {recentBoards.map(board => (
                            <div
                                className="result-item"
                                key={board._id}
                            >
                                {board.cover?.sizes?.thumbnail ? (
                                    <div
                                        className="icon board-icon"
                                        style={{ backgroundImage: `url(${board.cover?.sizes?.medium})` }}
                                    >
                                        {!user.user.starredBoards.includes(board._id) && (
                                            <StarFill color={'#FFD700'} />
                                        )}
                                    </div>
                                ) : (
                                    <div className="icon card-icon">
                                        {!user.user.starredBoards.includes(board._id) && (
                                            <StarFill color={'#FFD700'} />
                                        )}
                                    </div>
                                )}


                                <div className="content">
                                    <p className="title">{board.title}</p>
                                    <p className="subtitle">{board.workspace.name}</p>
                                </div>
                                <Link className="card-permalink" to={board.permalink}></Link>
                            </div>
                        ))}
                    </section>
                )
            )}
        </div>
    );
};

export default NavRecent;
