import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./scss/Tooltip.css";

const Tooltip = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [tooltipPosition, setTooltipPosition] = useState("top");

    useEffect(() => {
        const handleMouseEnter = (e) => {
            const target = e.target.closest("[data-tooltip-content]");
            if (target) {
                const rect = target.getBoundingClientRect();
                const tooltipContent = target.getAttribute("data-tooltip-content");
                const tooltipPosition = target.getAttribute("data-tooltip-position") || "bottom";

                let top = rect.top;
                let left = rect.left + rect.width / 2;

                // Adjust tooltip position
                switch (tooltipPosition) {
                    case "top":
                        top -= 20; // Tooltip above the element
                        break;
                    case "bottom":
                        top += rect.height + 18; // Tooltip below the element
                        break;
                    case "left":
                        left = rect.left - (rect.width + 8); // Tooltip to the left
                        top = 78; // Tooltip above the element

                        break;
                    case "right":
                        left = rect.right + 18; // Tooltip to the right
                        break;
                    default:
                        break;
                }

                setContent(tooltipContent);
                setTooltipPosition(tooltipPosition);
                setPosition({ top, left });
                setIsVisible(true);
            }
        };

        const handleMouseLeave = (e) => {
            if (e.target.closest("[data-tooltip-content]")) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mouseover", handleMouseEnter);
        document.addEventListener("mouseout", handleMouseLeave);

        return () => {
            document.removeEventListener("mouseover", handleMouseEnter);
            document.removeEventListener("mouseout", handleMouseLeave);
        };
    }, []);

    if (!isVisible) return null;

    return ReactDOM.createPortal(
        <div
            className={`tooltip tooltip-${tooltipPosition}`}
            style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                transform: "translate(-50%, -50%)",
            }}
        >
            {content}
        </div>,
        document.body
    );
};

export default Tooltip;
