import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './scss/CardTable.scss';
import ProfileNavbar from './ProfileNavbar';
import { find, toSlug } from '../../utils/helpers';
import { config } from '../../config';

const CardTable = () => {

    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);
    const workspaces = useSelector((state) => state.workspaces); // Example
    const [cards, setCards] = useState([]);

    useEffect(() => {
        if (!user) return; // Make sure the effect doesn't run if user is missing

        const fetchCards = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/${user?.user?._id}/cards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch cards');
                }

                const data = await response.json();
                setCards(data);
            } catch (error) {
                console.error('Error fetching cards:', error);
            }
        };

        fetchCards();
    }, [user]);

    if (!user) {
        return <div>Loading...</div>; // Return loading UI
    }


    return (
        <div className="profile-page">
            <ProfileNavbar />

            {
                (!userLoading) && (

                    <div className="empty-activity">
                        <h2>Coming soon</h2>
                    </div>
                )
            }
        </div>
    );
};

export default CardTable;
