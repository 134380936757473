export const config = {
    // API_URI: "https://zoobbe-board.onrender.com",
    // API_URI: "https://api-qcqo.onrender.com",

    // API_URI: "https://zoobbe-beta.onrender.com",
    API_URI: "https://api-v1.zoobbe.com",
    // API_URI: "http://localhost:5000",
    DOMAIN: "https://beta.zoobbe.com",
    IS_BETA: true,
    GOOGLE_AUTH_CLIENT_ID: "39531044046-0lrtdc7ojrq119nhg7fq14t8m27ae0so.apps.googleusercontent.com",


    // API_URI: "https://api.zoobbe.com",
};
