import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updatePopoverPosition } from '../redux/Slices/popoverSlice';
import { getScrollableAncestor } from '../utils/helpers';

const usePopoverPositionUpdater = (targetId, popoverRef) => {
    const dispatch = useDispatch();

    const updatePosition = useCallback(() => {
        const target = document.getElementById(targetId);
        if (!target || !popoverRef.current) return;

        const rect = target.getBoundingClientRect(); // Target's position
        const popoverRect = popoverRef.current.getBoundingClientRect(); // Popover's size
        const viewportWidth = window.innerWidth; // Viewport width
        const viewportHeight = window.innerHeight; // Viewport height

        const spacing = 4; // Spacing between the popover and target

        // Calculate available space around the target
        const spaceTop = rect.top;
        const spaceBottom = viewportHeight - rect.bottom;
        const spaceLeft = rect.left;
        const spaceRight = viewportWidth - rect.right;

        // Determine the best position for the popover
        let top = 0;
        let left = 0;

        if (spaceBottom >= popoverRect.height) {
            // Prefer opening below
            top = rect.bottom + window.scrollY + spacing;
        } else if (spaceTop >= popoverRect.height) {
            // Open above if space below is insufficient
            top = rect.top + window.scrollY - popoverRect.height - spacing;
        } else {
            // If neither fits, fit vertically within viewport
            top = Math.max(window.scrollY, rect.bottom + window.scrollY + spacing);
            top = Math.min(top, viewportHeight + window.scrollY - popoverRect.height);
        }

        if (spaceRight >= popoverRect.width) {
            // Prefer opening to the right
            left = rect.right + window.scrollX + spacing - rect.width;
        } else if (spaceLeft >= popoverRect.width) {
            // Open to the left if space on the right is insufficient
            left = rect.left + window.scrollX - popoverRect.width - spacing + rect.width;
        } else {
            // If neither fits, fit horizontally within viewport
            left = Math.max(window.scrollX, rect.left + window.scrollX);
            left = Math.min(left, viewportWidth + window.scrollX - popoverRect.width);
        }

        // Dispatch the position update
        dispatch(updatePopoverPosition({ top, left }));
    }, [dispatch, targetId, popoverRef]);

    useEffect(() => {
        if (!targetId) return;

        const target = document.getElementById(targetId);
        const scrollableAncestor = getScrollableAncestor(target);

        // Update position immediately on mount
        updatePosition();

        // Attach scroll and resize listeners to keep the popover position updated
        const handleScroll = updatePosition;
        const handleResize = updatePosition;

        scrollableAncestor?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            scrollableAncestor?.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [targetId, updatePosition]);

    useEffect(() => {
        updatePosition(); // Recalculate position on changes
    }, [targetId, popoverRef, updatePosition]);
};

export default usePopoverPositionUpdater;
