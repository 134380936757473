import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk to fetch all boards
export const fetchBoards = createAsyncThunk(
    'boards/fetchBoards',
    async (_, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/boards`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch boards');
        }

        return response.json();
    }
);

export const fetchBoardLists = createAsyncThunk(
    'boards/fetchBoardLists',
    async (boardId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/boards/${boardId}/lists`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch board lists');
        }

        return { boardId, actionLists: await response.json() };
    }
);

// Async thunk to fetch starred boards
export const fetchStarredBoards = createAsyncThunk(
    'boards/fetchStarredBoards',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/users/me/get-starred`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Failed to fetch starred boards');
            }

            return response.json(); // Assuming the API returns an array of starred boards
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred while fetching starred boards');
        }
    }
);

export const fetchRecentBoards = createAsyncThunk(
    'boards/fetchRecentBoards',
    async ({ limit }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/users/me/recent-viewed?limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Failed to fetch recent boards');
            }

            return response.json(); // Assuming the API returns an array of recent boards
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred while fetching recent boards');
        }
    }
);

export const fetchBoardsByWorkspaceAndMember = createAsyncThunk(
    'boards/fetchBoardsByWorkspaceAndMember',
    async ({ workspaceId, memberId }, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/members/${workspaceId}/${memberId}/boards`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch boards for the member in the workspace');
        }

        return response.json();
    }
);

const boardsSlice = createSlice({
    name: 'boards',
    initialState: {
        boards: [],
        memberBoards: [],
        starredBoards: [],
        recentBoards: [],
        actionLists: {},
        status: 'idle',
        starredStatus: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all boards
            .addCase(fetchBoards.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBoards.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.boards = action.payload;
            })
            .addCase(fetchBoards.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Fetch boards by workspace and member
            .addCase(fetchBoardsByWorkspaceAndMember.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBoardsByWorkspaceAndMember.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.memberBoards = action.payload; // Ensure this matches the API response structure
            })            
            .addCase(fetchBoardsByWorkspaceAndMember.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Fetch board lists
            .addCase(fetchBoardLists.fulfilled, (state, action) => {
                state.actionLists[action.payload.boardId] = action.payload.actionLists;
            })
            .addCase(fetchBoardLists.rejected, (state, action) => {
                state.error = action.payload;
            })
            // Fetch starred boards
            .addCase(fetchStarredBoards.pending, (state) => {
                state.starredStatus = 'loading';
            })
            .addCase(fetchStarredBoards.fulfilled, (state, action) => {
                state.starredStatus = 'succeeded';
                state.starredBoards = action.payload;
            })
            .addCase(fetchStarredBoards.rejected, (state, action) => {
                state.starredStatus = 'failed';
                state.error = action.payload;
            })
            // Fetch recent view boards
            .addCase(fetchRecentBoards.pending, (state) => {
                state.recentStatus = 'loading';
            })
            .addCase(fetchRecentBoards.fulfilled, (state, action) => {
                state.recentStatus = 'succeeded';
                state.recentBoards = action.payload;
            })
            .addCase(fetchRecentBoards.rejected, (state, action) => {
                state.recentStatus = 'failed';
                state.error = action.payload;
            });
    },
});



export default boardsSlice.reducer;