import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';

import ImagePlaceholder from '../Global/ImagePlaceholder';
import './scss/index.scss';

const ProfileDropdown = ({ setIsShowProfile }) => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hideProfile = () => setIsShowProfile(false);

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
        hideProfile();
    };

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('accessToken');
        navigate('/login');
        window.location.reload();
    };

    return (
        <div className="zoobbe-profile">
            <div className="account-section">
                {user?.user?.profilePicture ? (
                    <img
                        className="profile-picture"
                        src={user.user.profilePicture}
                        alt={user.user.username}
                    />
                ) : (
                    <ImagePlaceholder
                        key={user.user._id}
                        size={50}
                        text={user.user.username}
                    />
                )}
                <div className="account-info">
                    <p className="username">{user.user.username}</p>
                    <p className="email">{user.user.email}</p>
                </div>
            </div>
            <div className="manage-account-section">
                {/* <a href="#" className="manage-account">Manage account</a> */}
            </div>
            <div className="menu-section">
                <p className="menu-title">Zoobbe</p>
                <ul>
                    <li><Link onClick={hideProfile} to={`/u/${user.user.username}/profile`}>Profile and visibility</Link></li>
                    {user.user.verified && (
                        <>
                            <li><Link onClick={hideProfile} to={`/u/${user.user.username}/activity`}>Activity</Link></li>
                            <li><Link onClick={hideProfile} to={`/u/${user.user.username}/cards`}>Cards</Link></li>
                            <li><Link onClick={hideProfile} to={`/u/${user.user.username}/settings`}>Settings</Link></li>
                        </>
                    )}
                    <li className='full-deactivated'><Link to="#">Theme</Link></li>
                </ul>
            </div>
            {user.user.verified && (
                <div className="workspace-section">
                    <button className="create-workspace" onClick={handleCreateWorkspace}>
                        <span className="material-symbols-outlined">workspaces</span>
                        Create Workspace
                    </button>
                </div>
            )}
            <div className="other-section">
                <ul>
                    <li className='full-deactivated'><Link to="#">Help</Link></li>
                </ul>
            </div>
            {user.user.verified && (
                <div className="workspace-section">
                    <Link className="export-import-board" onClick={hideProfile} to="/import-export">
                        <span className="material-symbols-outlined">import_export</span>
                        Import / Export
                    </Link>
                </div>
            )}
            <div className="logout-section">
                <ul>
                    <li>
                        <Link to="#" onClick={handleLogout}>
                            <span className="material-symbols-outlined">logout</span>
                            Log out
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProfileDropdown;
